/**
 * Data View for Contact Create data JS
 */

// strict mode
"use strict";

/* global moment, MDD_Pagination */


jQuery(document).ready(

    function () {

        var dataContactCreate = jQuery('#dataContactCreate'),
            dataFilter  = dataContactCreate.children('.list-filter'),
            dataLoading = dataContactCreate.children('.loading'),
            dataResults = dataContactCreate.children('.results'),
            dataMessage = null,
            dataActions = dataContactCreate.children('.mass-actions'),
            actionRetest = dataActions.find('button[name="retest-open"]'),
            actionPush   = dataActions.find('button[name="push-open"]'),
            actionType   = dataActions.find('input[name="default_createtype"]'),
            actionDivs   = dataActions.find('textarea[name="qualified_divisions"]'),
            actionWork   = dataActions.find('[name*="_wrkactopt_"]'),

            curPage     = 1,
            perPage     = 20,
            pagination  = null;

        if (dataContactCreate.length) {
            // Listen for Request Info button clicks
            dataResults
                .on(
                    'click',
                    '.request-info',
                    showRequestInfoFromRow
                );
            // Set pagination
            pagination = new MDD_Pagination(paginationChange);
            dataResults
                .prepend(pagination.buildControls())
                .append(pagination.buildControls());
            // Actions
            actionRetest.on('click', action_retest_open);
            actionPush.on('click', action_push_open);
            actionType.on('click', action_set_type);
            actionDivs.on('change', action_set_qualified_divisions);
            actionWork.on('change', action_set_workaction_options);
            dataActions.removeClass('d-none');
            // Listen for changes to filter
            dataFilter
                // Don't let form submit page.
                .on('submit', function(e) { e.preventDefault(); e.stopPropagation(); return false; })
                // Value change update results
                .on('change', filterValuesChanged)
                // Force update
                .trigger('change')
                // Show filter
                .removeClass('d-none');
       }

        /**
         * Filter value changed. Reset paging to 1 and update results.
         */
        function filterValuesChanged() {
            curPage = 1;
            updateResultsFromFilter();
        }


        /**
         * Handle user selection of new page.
         * Update currect page and update results.
         * @param integer Page (1 based)
         */
        function paginationChange(pg) {
            curPage = pg;
            updateResultsFromFilter();
        }


        /**
         * Update Pagination information.
         * @see updateResultsFromFilter()
         * @param integer Offset value
         */
        function updatePagination(totalRecords, newOffset) {
            // curPage is 1-based, offset is 0-based, adjust
            curPage = Math.floor((newOffset + 1) / perPage) + 1;
            pagination.updateControls(totalRecords, perPage, curPage);

        }


        /**
         * Action - Retest Open Requests
         */
        function action_retest_open(e) {
            e.preventDefault();
            if (confirm('This may take several minutes if there are a lot of requests. Continue?')) {
                updateResultsFromFilter({ retest_open: true });
            }
        }

        /**
         * Action - Push Open Records
         */
        function action_push_open(e) {
            e.preventDefault();
            if (confirm('This may take several minutes if there are a lot of requests. Continue?')) {
                updateResultsFromFilter({ push_open: true });
            }
        }

        /**
         * Action - Set default type
         */
        function action_set_type(e) {
            var value = jQuery(e.target).val();

            jQuery.getJSON(
                '?ajr=ContactCreate&default_type=' + value
            )
            .fail(ajaxError)
            .done(
                function(json) {
                    setMessageResults(json);
                }
            );
        }

        /**
         * Action - Set Qualified Divisions List
         */
        function action_set_qualified_divisions(e) {
            var data = { qualified_divisions: jQuery(e.target).val() };

            jQuery.post(
                '?ajr=PivotalContactAutoHubSpot',
                data
            )
            .fail(ajaxError)
            .done(
                function(data) {
                    var json = jQuery.parseJSON(data);
                    setMessageResults(json);
                    if ('updated' in json) {
                        actionDivs.val(json.updated.join("\n"));
                    }
                }
            );
        }

        /**
         * Handle change to WorkAction options
         */
        function action_set_workaction_options(e) {
            var target  = jQuery(e.target),
                name    = target.attr('name'),
                value   = target.val(),
                data    = {},
                uri     = target.closest('.workaction-option').attr('data-uri');

            data[name] = value;
            jQuery.post(
                uri,
                data
            )
            .fail(ajaxError)
            .done(
                function(data) {
                    var json = jQuery.parseJSON(data);
                    setMessageResults(json);
                    // if ('updated' in json) {
                    //     actionDivs.val(json.updated.join("\n"));
                    // }
                }
            );
        }


        /**
         * Get filter values and update results.
         * @param object If passed property/values will be included in sent data
         */
        function updateResultsFromFilter(additionalData) {
            var request = getDataFilterValues(), k;

            dataContactCreate.addClass('is-loading');
            dataLoading.removeClass('d-none');

            // Merge in additionalData if any passed
            if (typeof additionalData === 'object') {
                for (k in additionalData) {
                    if (additionalData.hasOwnProperty(k))
                        request[k] = additionalData[k];
                }
            }

            request.limit = perPage;
            request.offset = (curPage - 1) * perPage;
            jQuery.getJSON(
                '?ajr=ContactCreate&getReq=1',
                request
            )
            .fail(ajaxError)
            .done(
                function(data) {
                    updatePagination(data.total, data.offset);
                    setResults(data.requests);
                    setDataFilterFromValues(data.filter);
                    setMessageResults(data);
                }
            )
            .always(
                function() {
                    dataLoading.addClass('d-none');
                    dataResults.removeClass('d-none');
                    dataContactCreate.removeClass('is-loading');
                }
            );
        }


        /**
         * Update results table with passed data
         * @param array Request data to display
         */
        function setResults(requests) {
            var tableData = dataResults.find('table.table_requests');

            if (tableData.find('tr.request-header').length === 0) {
                jQuery('<thead>').append(
                    jQuery('<tr>').addClass('request-header').append(
                        ['date:Date', 'status:Status', 'source_result:Source Request / HubSpot Result', 'additional:Additional'].map(
                            function(col) {
                                var p = col.split(':');
                                return jQuery('<th>').addClass('c-' + p[0]).text(p[1]);
                            }
                        )
                    )
                )
                .appendTo(tableData);
            }

            tableData.find('tr.request-data').remove();
            if (requests.length) {
                requests.map(
                    function(request, index) {
                        var tr = jQuery('<tr>').addClass('request-data');

                        buildRequestRow(request, tr);
                        tr.appendTo(tableData);
                    }
                );
            }
            else {
                jQuery('<tr>')
                    .addClass('request-data')
                    .append(
                        jQuery('<td>')
                            .attr('colspan', '5')
                            .html('<div class="alert alert-warning">No requests found</div>')
                    )
                    .appendTo(tableData);
            }
        }


        /**
         * Message update
         * @param mixed ajax result.
         */
        function setMessageResults(data) {
            if (typeof data == 'object' && data) {
                if (data.hasOwnProperty('message')) {
                    setMessage(data.message);
                }
                // General error?
                if (data.hasOwnProperty('error') && typeof data.error == 'string' && data.error.length) {
                    ajaxError(null, data.error, data.error);
                }
            }
        }


        /**
         * Ajax error
         */
        function ajaxError(jqXHR, textStatus, errorThrown) {
            console.log(jqXHR, textStatus, errorThrown);
            alert(textStatus);
        }


        /**
         * Set message if any
         * @param object Message, properties
         *   'title - option title
         *   'text' or 'html' - actual message content
         *   'type' - either info, danger, success, warning
         */
        function setMessage(msg) {
            var type = 'info';
            if (msg && typeof msg == 'object') {
                if ('type' in msg) {
                    type = msg.type;
                }
                // Has a message alert been created?
                if (dataMessage === null) {
                    dataMessage = jQuery('<div role="alert" />').addClass('alert alert-dismissible alert-' + type)
                        .css( { position: 'fixed', top: '1rem', left: '1rem' })
                        .append(
                            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                            + '<span aria-hidden="true">&times;</span>'
                            + '</button>'
                            + '<ul class="alert-contents" />'
                        );
                    dataContactCreate.append(dataMessage);
                    dataMessage
                        .on(
                            'close.bs.alert',
                            function(_e) {
                                dataMessage = null;
                            }
                        );
                }
                // Append message to contents
                dataMessage.find('ul.alert-contents')
                    .append(
                        '<li>'
                        + ('title' in msg
                            ? '<h3>' + msg.title + '</h3>'
                            : ''
                        )
                        + ('html' in msg
                            ? msg.html
                            : htmlspecialchars(msg.text)
                        )
                        + '</li>'
                    );
            }
        }


        /**
         * Build row from request data
         * @param object Request
         * @param element TR
         */
        function buildRequestRow(request, tr) {
            var tds = [],
                work;

            if (request.date_done) {
                work = 'Done ' + date_format(request.date_done);
            }
            else if (request.date_push) {
                work = 'Pushed ' + date_format(request.date_push);
            }
            else {
                work = 'Opened ' + date_format(request.date_open);
            }
            tds.push(
                jQuery('<td>') .append(
                    jQuery('<a>')
                    .addClass('c-date')
                    .text(work)
                    .addClass('btn btn-sm btn-outline-primary request-info')
                )
            );
            work = request.status
                + (request.enabled != 'Y' ? ' <i class="text-danger cil-warning"></i>' : '');

            tds.push( jQuery('<td>').addClass('c-status').html(work) );

            work = { source: '- not set -', result: '- not set -' };
            if (request.data_id > 0) {
                work.source = '<strong>#' + htmlspecialchars(request.data_id) + '</strong>'
                    + (request.pv_contact_name && request.pv_contact_name.length ? ', ' + htmlspecialchars(request.pv_contact_name) : '')
                    + (request.pv_contact_email && request.pv_contact_email.length ? ', ' + htmlspecialchars(request.pv_contact_email) : '')
                    + (request.pv_contact_inactive && request.pv_contact_inactive.length ? ' <span class="text-danger">[INACTIVE]</span>' : '');
            }

            if (request.hubspot_id > 0) {
                work.result = '<strong>#' + htmlspecialchars(request.hubspot_id) + '</strong>';
                if ('hs_contact_link' in request && request.hs_contact_link && request.hs_contact_link.length) {
                    work.result += jQuery('<div>').append(
                        jQuery('<a>')
                            .attr('href', request.hs_contact_link)
                            .attr('target', '_blank')
                            .html('<span class="sr-only">HubSpot</span class="sr-only"> <i class="cib-hubspot" aria-hidden="true"></i>')
                    ).html();
                }
                work.result += request.hs_contact_name && request.hs_contact_name.length  ? ', ' + htmlspecialchars(request.hs_contact_name)  : '';
                work.result += request.hs_contact_email && request.hs_contact_email.length ? ', ' + htmlspecialchars(request.hs_contact_email) : '';
            }
            tds.push( jQuery('<td>').addClass('c-source_result')
                .html(
                    '<span>Source:</span> ' + work.source
                    + '<br>'
                    + '<span>HubSpot:</span> ' + work.result
                )
            );


            work = [];
            // Are there Actions
            if (typeof request.actions === 'object' && Array.isArray( request.actions ) && request.actions.length ) {
                work.push(
                    htmlspecialchars(request.actions.length.toString() + (request.actions.length > 1 ? ' Actions' : ' Action'))
                );
            }
            if (request.opener) {
                work.push(htmlspecialchars('Opener: ' + request.opener));
            }
            if (request.date_stop) {
                tr.addClass('disabled-request');
                work.push(
                    '<span class="request-error-msg text-danger">' + htmlspecialchars(date_format(request.date_stop) + ': ' + request.error_msg) + '</span>'
                );
            }
            else {
                tr.removeClass('disabled-request');
            }
            tds.push( jQuery('<td>').addClass('c-additional').html(work.join('<br>')) );
            tr.empty()
                .append(tds)
                .attr('data-uid', request.uid)
                .attr('id', 'request-' + request.uid)
                .data('request', request);
        }


        /**
         *
         * @param object Values of form items to set
         */
        function setDataFilterFromValues(values) {
            var key, value, els, vs, i;

            for (key in values) {
                if (values.hasOwnProperty(key)) {
                    value = values[key];
                    els = dataFilter.find('[name="' + key + '"]');

                    if (els.length) {
                        // Is ele radio or checkbox?
                        if (els.attr('type') == 'radio' || els.attr('type') == 'checkbox') {
                            els.prop('checked', false);
                            vs = Array.isArray(value) ? value : [value];
                            for (i = 0; i < vs.length; i++) {
                                els.filter('[value="' + vs[i] + '"]').prop('checked', true);
                            }
                        }
                        // Text, Textarea, or Select
                        else {
                            els.val(value);
                        }
                    }
                }
            }
        }

        function getDataFilterValues() {
            var data = {};
            dataFilter.find('input[type=radio]:checked, input[type=checkbox]:checked').each(
                function(_index, _value) {
                    var ele = jQuery(this);
                    data[ ele.attr('name') ] = ele.val();
                }
            );
            dataFilter.find('input[type=text], input[type=number], select').each(
                function(_index, _value) {
                    var ele = jQuery(this);
                    data[ ele.attr('name') ] = ele.val();
                }
            )
            return data;
        }


        /**
         * Show info for a Request
         * Called from table row cell with link to detail.
         */
        function showRequestInfoFromRow(e) {
            var request_row = jQuery(e.target).closest('tr'),
                request = request_row.data('request');

            // Prevent link default behavior
            e.preventDefault();
            // Open modal and show detali
            showRequestInfo(request);
        }


        /**
         * For passed request data, display modal with detail data
         * @param object request
         */
        function showRequestInfo(request) {
            var editable = request.status == 'Open',
                // Create or get modal structure
                modal = getModal(),
                modal_title = modal.find('.modal-title').empty(),
                modal_body = modal.find('.modal-body').empty(),
                close_button = jQuery('<button>').attr('type', 'button').attr('data-dismiss', 'modal').attr('aria-label', 'Close').addClass('btn btn-primary').text('Close'),
                modal_footer = modal.find('.modal-footer').empty().append(close_button),
                heading = jQuery('<h2>').text('Status: ' + request.status),
                subhead = jQuery('<small>').addClass('ml-2'),
                buttons = [],
                reqMeta = {},
                body = jQuery('<div>').addClass('request-detail'),
                work, p;

            // Our buttons may want to know what request is displayed
            modal.data('request_uid', request.uid);

            // Opener given credit?
            if (request.opener) reqMeta.opened_by = request.opener;
            // Open Date
            reqMeta.opened = date_format(request.date_open, 'dddd, MMMM Do YYYY, h:mm a');
            // Has it been pushed
            if (request.date_push) reqMeta.pushed = date_format(request.date_push, 'dddd, MMMM Do YYYY, h:mm a');
            // Has if been confirmed (done)
            if (request.date_done) reqMeta.confirmed = date_format(request.date_done, 'dddd, MMMM Do YYYY, h:mm a');
            // Options
            reqMeta.options = drawObjectList(request.options, editable, 'option_');

            // Status explaination and buttons setup
            if (request.status === 'Open') {
                heading.append(
                    request.enabled === 'Y'
                        ? subhead.text('Waiting to be pushed to HubSpot.')
                        : subhead.addClass('text-danger').text('Disabled ' + date_format(request.date_stop))
                );
                buttons = [
                    jQuery('<button>').attr('type', 'button').addClass('btn btn-secondary').text('Delete').on('click', detail_delete),
                    jQuery('<button>').attr('type', 'button').addClass('btn btn-secondary').text('Push to HubSpot').on('click', detail_push),
                    jQuery('<button>').attr('type', 'button').addClass('btn btn-primary').text(request.enabled == 'Y' ? 'Save' : 'Save & Enable').on('click', detail_save),
                ];
                body.addClass('request-new-data').append('<h3 class="mt-3">Data Available to Complete Form</h3>');
                if (editable) {
                    body.append(
                        '<div class="alert alert-info mt-3">'
                            + 'Editing ID values is not advisable. Data here reflects the field values of the contact at the time the request was opened.'
                        + '</div>'
                    );
                }
                body.append(
                    drawObjectList(request.new_data, editable, editable  ? 'edit_' : 'view_')
                );
            }

            else {
                heading.append(
                    subhead.text(
                        request.status === 'Push'
                            ? 'Showing pushed to HubSpot as Contact Form submission, waiting on confirmation.'
                            : 'Confirmed created (or updated) HubSpot Contact.'
                    )
                );
                // Only buttons applicable to 'Push' status
                if (request.status === 'Push') {
                    buttons = [
                        jQuery('<button>').attr('type', 'button').addClass('btn btn-secondary').text('Reset').on('click', detail_reset),
                    ];
                }
                // Build push_data display
                work = {
                    portal_id  : request.push_data.portal_id,
                    form_id    : request.push_data.formid,
                    formid_key : request.push_data.formid_key,
                    form_data  : {}
                };
                for (p = 0; p < request.push_data.fields.length; p++) {
                    work.form_data[ request.push_data.fields[p].name ] = request.push_data.fields[p].value;
                }
                work.form_data = drawObjectList(work.form_data, false, 'formdata_');
                body.append(
                    jQuery('<div />').addClass('push-data-list')
                        .append( '<h3 class="mt-3">Form Submission Details</h3>' )
                        .append( drawObjectList(work, false, 'frmdata_') )
                        .append( '<h3 class="mt-3">Submission (HubSpot) Response</h3>' )
                        .append(
                            typeof request.push_response === 'object'
                                ? drawObjectList(request.push_response, false, 'response_')
                                : jQuery('<p>').text(request.push_response)
                        )
                );
            }

            // Actions
            if (typeof request.actions === 'object' && Array.isArray(request.actions) && request.actions.length) {
                var tcols = ['action:Action', 'payload:Data', 'status:Status']
                        .map(
                            function(col, idx) {
                                var parts = col.split(':');
                                return { field: parts[0], label: parts[1], index: idx };
                            }
                        );

                work = jQuery( '<table />' ).addClass( 'table' ).addClass( 'table-striped' ).append(
                    // Header
                    jQuery( '<thead />' ).append(
                        jQuery( '<tr />' ).append(
                            tcols.map(
                                function(col) {
                                    return jQuery('<th />').addClass('c2-' + col.field).text(col.label);
                                }
                            )
                        )
                    )
                );
                work.append(
                    // Body
                    jQuery( '<tbody />' ).append(
                        request.actions.map(
                            function(action) {
                                return jQuery( '<tr />' ).css('vertical-align', 'top').attr( 'data-action-uid', action.uid ).append(
                                    tcols.map(
                                        function(col) {
                                            var td = jQuery( '<td />' ).addClass('c2-' + col.field),
                                                val = action.hasOwnProperty(col.field) ? action[col.field] : null,
                                                build = [];
                                            switch (col.field) {
                                                case 'uid':
                                                    td.text( '#' + val );
                                                    break;

                                                case 'action':
                                                    td.text( val + ' (#' + action.uid + ')' );
                                                    break;

                                                case 'status':
                                                    build.push( '<strong>' + val + '</strong>' );
                                                    if ( action.error_msg ) {
                                                        build.push(  '<div style="color:red;">' + htmlspecialchars(action.error_msg) + '</div>' );
                                                    }
                                                    // Date - open date unless completed
                                                    build.push( date_format( action.date_done ? action.date_done : action.date_open) );
                                                    // if ( action.date_done ) {
                                                    //     build.push( '<span class="lbl">End:</span> ' + date_format( action.date_done ) );
                                                    // }
                                                    // else {
                                                    //     build.push( '<span class="lbl">Beg:</span> ' + date_format( action.date_open ) );
                                                    // }
                                                    td.html( build.join('<br>') );
                                                    break;

                                                case 'date_open':
                                                case 'date_done':
                                                    td.text( typeof val !== undefined ? date_format(val) : 'none' );
                                                    break;

                                                // case 'payload':
                                                //     val = jQuery.parseJSON( val );
                                                //     td.html( drawMixedValue(val) );

                                                //     break;

                                                default:
                                                    td.text(val);
                                                    break;
                                            }
                                            return td;
                                        }
                                    )
                                )
                            }
                        )
                    )
                );
                // for (var action in request.actions) {
                //     jQuery( '<tr />' )
                //         .append( '<td />' ).text( '#' + action.uid )
                //         .append( '<td />' ).text( action.action )
                //         .append( '<td />' ).text( action.payload )
                //         .append( '<td />' ).text( action.status )
                //         .append( '<td />' ).text( action.error_msg )
                //         .append( '<td />' ).text( date_format(action.date_open) )
                //         .append( '<td />' ).text( action.date_done ? date_format(action.date_done) : 'open' )
                //         .appendTo( work );
                // }
                body.append(
                    jQuery('<div />').addClass('push-actions')
                        .append( '<h3 class="mt-3">Work Actions</h3>' )
                        .append( work )
                );
            }

            // Set title and content
            modal_title.html('HubSpot Contact Create Request for Pivotal Contact #' + request.data_id);
            modal_body.append( heading );
            modal_body.append( drawObjectList(reqMeta, false, 'request_') );
            // Is there a problem?
            if (request.date_stop)
                modal_body.append(
                    jQuery('<div />').addClass('alert alert-danger mt-3').html(
                        htmlspecialchars('Stopped ' + date_format(request.date_stop) + ': ' + request.error_msg)
                        )
                    );

            modal_body.append(body);

            // Add buttons to footer, alert primary
            if (buttons.length) {
                if (buttons.filter(function(b) { return b.hasClass('btn-primary'); }).length > 0) {
                    close_button.removeClass('btn-primary').addClass('btn-secondary');
                }
                modal_footer.append(buttons)
            }

            // Display
            modal.modal('show');
        }


        function drawMixedValue(value) {
            var result = '';
            // Is object or array?
            if (typeof value === 'object') {
                // Array?
                if (Array.isArray(value) && value.length) {
                    result = '<ol class="object-array">';
                    for (var i = 0; i < value.length; i++) {
                        result += '<li>' + drawMixedValue(value[i]) + '</li>';
                    }
                    result += '</ol>'
                }
                // Object
                else {
                    result = '<div class="objec-list">';
                    for (var key in value) {
                        result += '<div class="row">'
                            + '<span class="col-sm-3">' + htmlspecialchars(key) + '</span>'
                            + '<span class="col-sm-9">' + drawMixedValue(value[key]) + '</span>'
                            + '</div>';
                    }
                    result += '</div>';
                }
            }
            else if (['string', 'number', 'boolean'].indexOf(typeof value) >= 0) {
                result = new String(value);
            }

            return result;
        }


        /**
         * Draw table for object values
         * @param object Key Values to display
         * @param editable
         * @param prefix
         * @param object Previous result - rows are appended
         * @return object
         */
        function drawObjectList(data, editable, prefix, appendTo) {
            var result = typeof appendTo !== 'object' ? jQuery('<div>').addClass('obj-list') : appendTo,
                key, id, row, val;

            for (key in data) {
                if (data.hasOwnProperty(key)) {
                    id = prefix + key;
                    row = jQuery('<div>')
                        .addClass('row')
                        .append(
                            jQuery('<div>').addClass('col-sm-2').append(
                                jQuery('<label>').attr(editable ? 'for' : 'data-for', id).text(
                                    (key + ' ').replace(/^(\w)|(_+[\w])/g, function($1) { return $1.replace(/^_+/, ' ').toUpperCase(); })
                                )
                            )
                        );
                    if (typeof data[key] == 'object') {
                        val = data[key];
                    }
                    else if (editable) {
                        val = jQuery('<input>').attr('type', 'text').attr('name', id).css('width', '100%').attr('id', id).val(data[key])
                    }
                    else {
                        val = jQuery('<strong>').attr('id', id).text(data[key])
                    }
                    row.append(
                        jQuery('<div>').addClass('col-sm-10').append(val)
                    );
                    row.appendTo(result);
                }
            }
            return result;
        }


        /**
         * Delete request being view/edited
         * @see ::showRequestInfo
         * @param object Event
         */
        function detail_delete(e) {
            var modal = null,
                request_uid,
                data = {};

            e.preventDefault();
            if (!confirm('Really delete this Request?')) {
                return;
            }
            modal = getModalLoading('Deleting Request …');
            request_uid = modal.data('request_uid');
            jQuery.getJSON(
                '?ajr=ContactCreate&delReq=' + request_uid,
                data
            )
            .fail(ajaxError)
            .done(
                // Should be id of record deleted
                function(data) {
                    if (typeof data == 'object' && data) {
                        // Success
                        if (data.hasOwnProperty('error') && data.error === null) {
                            jQuery('#request-' + request_uid).remove();
                        }
                        setMessageResults(data);
                    }
                    else {
                        ajaxError(null, data, data);
                    }
                }
            )
            .always(
                function() {
                    modal.modal('hide');
                }
            );
        }


        /**
         * Reset request being view/edited
         * @see ::showRequestInfo
         * @param object Event
         */
        function detail_reset(e) {
            var modal = null,
                request_uid,
                data = {};

            e.preventDefault();
            if (!confirm('Really reset this Request? This will change the request back to Open status.')) {
                return;
            }
            modal = getModalLoading('Reseting Request …');
            request_uid = modal.data('request_uid');
            jQuery.getJSON(
                '?ajr=ContactCreate&resetReq=' + request_uid,
                data
            )
            .fail(ajaxError)
            .done(
                // Should be id of record deleted
                function(data) {
                    var tr = jQuery('#request-' + request_uid)
                    if (typeof data == 'object' && data) {
                        // Success
                        if (data.hasOwnProperty('request') && data.request === null) {
                            buildRequestRow(data.request, tr);
                            showRequestInfo(data.request);
                        }
                        setMessageResults(data);
                    }
                    else {
                        ajaxError(null, data, data);
                    }
                }
            )
            .always(
                function() {
                    modal.modal('hide');
                }
            );
        }


        /**
         * Push open request to HubSpot
         * @see ::showRequestInfo
         * @param object Event
         */
        function detail_push(e) {
            var modal = null,
                request_uid,
                data = {};

            e.preventDefault();
            if (!confirm('Attempt to Push this request to HubSpot as a Contact?')) {
                return;
            }
            modal = getModalLoading('Pushing Request …');
            request_uid = modal.data('request_uid');
            jQuery.getJSON(
                '?ajr=ContactCreate&pushReq=' + request_uid,
                data
            )
            .fail(ajaxError)
            .done(
                // Should be object with updated request
                function(data) {
                    var tr = jQuery('#request-' + request_uid)
                    if (typeof data == 'object' && data) {
                        if (data.hasOwnProperty('request') && data.request !== null) {
                            buildRequestRow(data.request, tr);
                            showRequestInfo(data.request);
                        }
                        setMessageResults(data);
                    }
                    else {
                        ajaxError(null, data, data);
                    }
                }
            );
        }


        /**
         * Save request being view/edited
         * @see ::showRequestInfo
         * @param object Event
         */
        function detail_save(e) {
            var modal = null,
                request_uid,
                data = {};

            e.preventDefault();
            modal = getModalLoading('Saving Request …');
            modal.find('.modal-body input[name^="edit_"], .modal-body input[name^="option_"]').each(
                function(index) {
                    var name = jQuery(this).attr('name');
                    if (name.match(/^(edit|option)_/)) {
                        data[name] = jQuery(this).val();
                    }
                }
            )
            request_uid = modal.data('request_uid');
            jQuery.getJSON(
                '?ajr=ContactCreate&saveReq=' + request_uid,
                data
            )
            .fail(ajaxError)
            .done(
                // Should be updated record
                function(data) {
                    var tr = jQuery('#request-' + request_uid)
                    if (typeof data == 'object' && data) {
                        if (data.hasOwnProperty('request') && data.request !== null) {
                            buildRequestRow(data.request, tr);
                        }
                        setMessageResults(data);
                    }
                    else {
                        ajaxError(null, data, data);
                    }
                }
            )
            .always(
                function() {
                    modal.modal('hide');
                }
            );
        }


        /**
         * Get Modal and set to Loading
         * @param string Message to use in header of modal
         * @return object Modal
         */
        function getModalLoading(message) {
            var modal = getModal(),
                loaderCSS = {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                };

            modal.find('.modal-title').text(message);
            modal.find('.modal-footer').empty().append(
                jQuery('<div />').addClass('alert alert-info').text(message)
            );
            modal.find('.modal-body').append(
                jQuery('<div />').addClass('loading').css(loaderCSS)
            );

            return modal;
        }


        /**
         * Create or get modal stucture
         * @return object Model container
         */
        function getModal() {
            var modal = jQuery('#detailModal');

            if (modal.length === 0) {
                modal = jQuery(
                    '<div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModalLabel" aria-hidden="true"> \
                        <div class="modal-dialog modal-xl" role="document"> \
                            <div class="modal-content"> \
                                <div class="modal-header"> \
                                <h5 class="modal-title" id="detailModalLabel">Modal title</h5> \
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
                                    <span aria-hidden="true">&times;</span> \
                                </button> \
                                </div> \
                                <div class="modal-body"></div> \
                                <div class="modal-footer"></div> \
                            </div> \
                        </div> \
                    </div>');
                modal.appendTo(document.body);
                modal.on(
                    'click',
                    '.btn',
                    modalButtonClick
                );
            }
            return modal;
        }


        /**
         * Modal 'btn' clicks.
         * @param object Event
         */
        function modalButtonClick(e) {
            // TODO
        }



        function htmlspecialchars(str) {
            return jQuery('<div />').text(String(str).replace(/\n/g, '[NL]')).html().replace(/\[NL\]/g, '<br>');
        }


        function date_format(dateStr, displayFmt) {
            var matches = typeof dateStr === 'string' ? dateStr.match(/^(\d\d\d\d-\d\d-\d\d)( (\d\d:\d\d:\d\d))?$/) : null,
                isDateTime, dateFmt;

            if (matches) {
                isDateTime = matches[3] !== undefined;
                dateFmt = isDateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
                if (typeof displayFmt === 'undefined') {
                    displayFmt = isDateTime ? 'MM/DD/YY hh:mm a' : 'MM/DD/YY';
                }
                return moment(dateStr, dateFmt).format(displayFmt);
            }
            return dateStr;
        }
    }

);
