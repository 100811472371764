/**
 * Pipelines
 */


// strict mode
"use strict";



jQuery(document).ready(

    function () {

        // Create text area for each Pipeline stage PV stage mapping
        jQuery('.mdd-pipeline-stagepv')
            .each(
                function (index) {
                    var container = jQuery(this),
                        input = jQuery('<textarea class="pipelineStagePV-Edit" />')
                            .attr('name', 'pvStage' + index.toString())
                            .val(container.text().replace(/(^[\s\n\r]+|[\s\n\r]+$)/g, ''))
                            .height('1.6em')
                            .width('100%');

                    container.empty().append(input);

                    input
                        .on(
                            'keyup',
                            function(e) {
                                jQuery(this).data('dirty', true);
                                this.style.height = Math.max(28, this.scrollHeight) + 'px';
                            }
                        )
                        .trigger('keyup');

                    input
                        .on(
                            'change',
                            function(e) {
                                var input = jQuery(this),
                                    data = {
                                        pipelineId: input.parent().attr('data-pipeline'),
                                        stageId: input.parent().attr('data-stage'),
                                        pivotal_opportunity_stage: input.val()
                                    };

                                jQuery.post('?ajr=dataCRMPipeline', data);
                            }
                        );
                }
            );

        // Check if there are any tr.inactive-stage items, if so enabled toggle
        if (jQuery('tr.inactive-stage').length) {
            jQuery('#inactive-stages').removeClass('d-none');
        }
    }
);
