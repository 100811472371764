/**
 * Pagination general tools
 */


// strict mode
"use strict";

/**
 * Constructor
 * @param callable Callback on pagination click = function(int <show-page>)
 */
function MDD_Pagination(callback) {
    this.total    = 0;
    this.perPage  = 0;
    this.curPage  = 0;
    this.maxPages = 0;
    this.pageCB   = callback;

    this.controls = null;
    this.controls_html = {
        first : '&lt;&lt;',
        prev  : '&lt;',
        next  : '&gt;',
        last  : '&gt;&gt;'
    };
}


/**
 * Build HTML for controls
 * @return object jQuery element to add to dom.
 */
MDD_Pagination.prototype.buildControls = function() {
    var controls = jQuery('<div class="qPagination" />')
        .append(
            jQuery('<ul />')
                .append('<li class="pg-first"><a href="#first" tabindex="-1">' + this.controls_html.first + '</a>')
                .append('<li class="pg-prev"><a href="#prev" tabindex="-1">' + this.controls_html.prev + '</a>')
                .append('<li><div /></li>')
                .append('<li class="pg-next"><a href="#next" tabindex="-1">' + this.controls_html.next + '</a>')
                .append('<li class="pg-last"><a href="#last" tabindex="-1">' + this.controls_html.last + '</a>')
        )
        .on(
            'click',
            'a',
            this.controlClicked.bind(this)
        );
    // Add to list of controls
    this.controls = this.controls === null
        ? jQuery(controls)
        : this.controls.add(controls);

    return controls;
}


/**
 * Update Controls.
 * NOTE: '1' Based Indexes (this.curPage=1 is first page)!
 * Change state of controls to reflect current properties.
 */
MDD_Pagination.prototype.updateControls = function(total, perPage, curPage) {
    var controls = jQuery(this.controls),
        cFirst = controls.find('.pg-first a'),
        cPrev  = controls.find('.pg-prev a'),
        cNext  = controls.find('.pg-next a'),
        cLast  = controls.find('.pg-last a'),
        stats  = controls.find('ul div'),
        showHide = [],
        p;

    this.total    = total;
    this.perPage  = perPage;
    this.maxPages = Math.ceil(total / perPage);
    this.curPage  = Math.max(1, Math.min(this.maxPages, curPage));

    cFirst.attr('data-pg', p = 1);
    showHide.push( [cFirst, this.curPage > 1] );

    cPrev.attr('data-pg', p = this.curPage - 1);
    showHide.push( [cPrev, p >= 1]);

    cNext.attr('data-pg', p = this.curPage + 1);
    showHide.push( [cNext, p < this.maxPages]);

    cLast.attr('data-pg', p = this.maxPages);
    showHide.push( [cLast, p > this.curPage]);

    showHide.forEach(
        function(sh, _idx) {
            // Show
            if (sh[1]) {
                sh[0].css('visibility', 'visible');
                sh[0].removeAttr('disabled');
            }
            // Hide
            else {
                sh[0].css('visibility', 'hidden');
                sh[0].attr('disabled', 'disabled');
            }
        }
    )
    stats.html(
        'Showing <strong>' + ((this.curPage - 1) * this.perPage + 1).toString() + '</strong>'
        + ' to <strong>' + Math.min(this.total, (this.curPage * this.perPage)).toString() + '</strong>'
        + ' of <strong>' + this.number_format(this.total) + '</strong>'
    );
}


/**
 * Click has happened on one of our controls
 * @param object Event
 */
MDD_Pagination.prototype.controlClicked = function(e) {
    var aTag = jQuery(e.target),
        page = aTag.attr('data-pg');

    e.preventDefault();
    // Call callback with page to show
    this.pageCB(page);
}


/**
 * Format numbers with thousand separator
 * @param integer Number
 * @return string
 */
MDD_Pagination.prototype.number_format = function(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
