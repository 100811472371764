/**
 * Downloads page
 */


// strict mode
"use strict";

/* global */

jQuery(document).ready(

    function() {
        if (jQuery('.export-files').length) {
            jQuery('.export-files .delete-confirm')
                .removeClass('d-none')
                .on(
                    'click',
                    function(e) {
                        var link = jQuery(this),
                            tr = link.closest('tr'),
                            file = tr.children('.name').attr('data-name');

                        e.preventDefault();
                        if (confirm('Really delete this file?')) {
                            if (link[0].nodeName !== 'A') {
                                link = link.closest('a');
                            }
                            link.hide();
                            jQuery.post(
                                link.attr('href'),
                                function(response) {
                                    tr.slideUp('fast');
                                }
                            );
                        }
                        return false;
                    }
                );
        }

    }
);
