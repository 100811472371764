/**
 * Status Activity - build charts with MDD activity
 */


// strict mode
"use strict";

/* global Chart, URLSearchParams */

jQuery(document).ready(

    function() {

        var chartRegion = jQuery('#status-activity .status-chart'),
            chartControls = jQuery('#status-activity .status-chart-controls'),
            chartJS = null,
            refresh = {
                timeout: null,
                delay: 1000,
                lastFocus: null
            },
            autoUpdate = {
                timeout: null,
                delay: 300 * 1000, // auto refresh chart
            },
            urlParams = new URLSearchParams(window.location.search),
            tokenFuncs = {
                /**
                 * Simple convert number value to integer
                 * @param string|integer Value
                 * @param index index
                 * @param array Values
                 * @return integer
                 */
                '#simpleNumber#': function(value, index, values) {
                    return Number(value.toString());
                }
            };

        // Range Input changes
        window.inputRangeUpdate = function(element) {
            var input = jQuery(element),
                label = input.closest('.chart-cntl').find('label').first(),
                value = input.val(),
                days = Math.floor(value / 24),
                labelTmpl = input.attr('data-label-' + value);

            if (label.length) {
                if (typeof labelTmpl === "undefined") {
                    labelTmpl = input.attr('data-label');
                    if (typeof labelTmpl === "undefined") {
                        return;
                    }
                }
                if (days && labelTmpl.indexOf('Hours') >= 0) {
                    label.text(labelTmpl.replace(/%s/, days.toString() + ' Days ' + (value % 24).toString()));
                }
                else {
                    label.text(labelTmpl.replace(/%s/, value));
                }
            }
        };

        if (chartRegion.length) {
            // chart = new Chart(chartRegion, {
            //     type: 'bar',
            //     data: {
            //         labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            //         datasets: [{
            //             label: '# of Votes',
            //             data: [12, 19, 3, 5, 2, 3],
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(54, 162, 235, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(153, 102, 255, 0.2)',
            //                 'rgba(255, 159, 64, 0.2)'
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)'
            //             ],
            //             borderWidth: 1
            //         }]
            //     },
            //     options: {
            //         scales: {
            //             yAxes: [{
            //                 ticks: {
            //                     beginAtZero: true
            //                 }
            //             }]
            //         }
            //     }
            // });

            loadChartData(
                {
                    // Load activityStats from Query String if set
                    activityStats: urlParams.has('activityStats')
                        ? urlParams.get('activityStats')
                        : 'default'
                },
                init
            );
        }


        /**
         * Load a chart by name
         * @param object Options - should include { activityStats: <name-of-chart>}
         * @param callback (jsonresponse)
         * @return void
         */
        function loadChartData(options, callback) {
            // Get ready to update
            chartUpdatePrepare();
            // Request
            jQuery.getJSON(
                '?ajr=log',
                options,
                function(result) {
                    callback(result);
                    // Clean up
                    chartUpdateComplete();
                }
            );
        }


        /**
         * Add overlay to chart region, blur any focus, and clear all timeouts
         * @return void
         */
        function chartUpdatePrepare() {
            refresh.timeout = null;
            refresh.lastFocus = document.activeElement;
            if (refresh.lastFocus) refresh.lastFocus.blur();
            // Reset and stop auto update -f set
            if (autoUpdate.timeout) {
                clearTimeout(autoUpdate.timeout);
                autoUpdate.timeout = null;
            }
            // Create overlay to show something is updating and prevent changes
            chartRegion
                .css('position', 'relative')
                .append('<div class="chart-controls-overlay" style="display:absolute;top:0;right:0;bottom:0;left:0;backgound:#00000080;"></div>');
        }


        /**
         * Remove overlay, reset focus to where was (if)
         * @return void
         */
        function chartUpdateComplete() {
            var overlay = chartRegion.children('.chart-controls-overlay');

            overlay.fadeOut(500, function() { overlay.remove(); });
            if (refresh.lastFocus) refresh.lastFocus.focus();

            // Setup auto update
            if (autoUpdate.delay) {
                autoUpdate.timeout = setTimeout(doRefreshChart, autoUpdate.delay);
            }
        }


        /**
         * Create a chart with passed data
         * This will be called initially
         * @param object Chart configuration
         * @return void
         */
        function newChart(chart) {
            checkChartOptions(chart);
            chartJS = new Chart(chartRegion, chart);
            // If any inputRangeUpdate's force them
            jQuery('input[oninput^="inputRangeUpdate"]').each(
                function() { window.inputRangeUpdate(this); }
            );
        }


        /**
         * Update a chart.
         * This will be called when an option input changes
         * @param object Chart configuration
         * @return void
         */
        function updateChart(chart) {
            checkChartOptions(chart);
            chartJS.data = chart.data;
            chartJS.options = chart.options;
            chartJS.update();
            // If any inputRangeUpdate's force them
            jQuery('input[oninput^="inputRangeUpdate"]').each(
                function() { window.inputRangeUpdate(this); }
            );
        }


        /**
         * With passed chartData:
         *   Setup any controls
         *   Replace any function placeholders with functions
         * @param object Chart data
         * @return void
         */
        function checkChartOptions(data) {
            var controls = chartControls.children('.chart-cntl'),
                last = null;
            // Are there any controls
            if (Array.isArray(data.controls)) {
                data.controls.map(
                    function(control, _index, _list) {
                        var cntl = controls.filter('.cntl_' + control.name);
                        // Need to create the control
                        if (cntl.length === 0) {
                            cntl = createControl(control);
                            chartControls.append(cntl);
                        }
                        // Reuse existing control
                        else {
                            controls = controls.not(cntl);
                        }
                        // Refresh/set properties
                        setControlProperties(cntl, control);
                        // Move after the last control
                        if (last) {
                            cntl.insertAfter(last);
                        }
                        last = cntl;
                        cntl.addClass('chart_cntl_active');
                    }
                );
                // Disable/hide controls not used
                controls.removeClass('chart_cntl_active').fadeOut('fast');
            }

            // Be sure data.functions is an array
            if (!Array.isArray(data.funcpaths)) data.funcpaths = [];
            data.funcpaths.map(
                function(path, index, _list) {
                    var obj = data, i, prop, idx;
                    try {
                        if (!Array.isArray(path)) {
                            throw "path should have been array but " + (typeof path) + " passed"
                        }
                        // Drill down into data following path as properties and array indexes
                        for (i = 0; i < path.length - 1; i++) {
                            prop = path[i];
                            if (Array.isArray(obj) && !isNaN(prop)) {
                                idx = parseInt(prop);
                                if (idx > obj.length - 1) {
                                    throw "No index " + prop + " in " + path.slice(0, i).join('.');
                                }
                                obj = obj[parseInt(prop)];
                            }
                            else if (typeof obj[prop] == "object" && isNaN(prop)) {
                                obj = obj[prop];
                            }
                            else {
                                throw "Fails at " + path.slice(0, i + 1).join('.');
                            }
                        } // end for
                        // Last item should be a string with the function token
                        prop = path[path.length - 1];
                        // The resulting obj must be a string to be valid
                        if (typeof obj[prop] == "string") {
                            // Is it a tokenFunc
                            if (typeof tokenFuncs[obj[prop]] == "function") {
                                obj[prop] = tokenFuncs[obj[prop]];
                            }
                            // Is it defined as a window function
                            else if (typeof window[obj[prop]] == "function") {
                                obj[prop] = window[obj[prop]];
                            }
                            // Can find it
                            else {
                                throw path.join('.') + " function token '" + obj[prop] + "' not found";
                            }
                        }
                        else {
                            throw "Last item is not a string with function token";
                        }
                    } // end try
                    catch (err) {
                        console.log('Error funcpaths[' + index + ']: ' + err);
                    } // end catch
                } // end map function
            ); // end map
        }


        /**
         * Set value, attributes, and label (if changed) for passed controlItem
         * @param object controlItem
         * @param object controlOpts Definition for control
         * @return void
         */
        function setControlProperties(cntlItem, cntlOpts) {
            // Actual control will be an input or select elemetn
            var ele = cntlItem.find('input, select'),
                label = ele.closest('.chart-cntl').find('label').first(),
                attr, attrSkip = 'name,type,label,value'.split(',');

            // Depending on type is how to set
            switch (cntlOpts.type) {
                case 'radio':
                case 'checkbox':
                case 'switch':
                    // TODO: Add support for checkbox, radio, switch reset
                    break;

                default:
                    // Set Value
                    ele.val(cntlOpts.value);
                    // With possible attributes
                    for (attr in cntlOpts) {
                        if (cntlOpts.hasOwnProperty(attr) && attrSkip.indexOf(attr) < 0) {
                            ele.attr(attr, cntlOpts[attr]);
                        }
                    }
                    break
            }

            // Label if found and control value
            label.text(cntlOpts.label);
        }


        /**
         * Create a control
         * @param object Control - minimum is 'name', 'type', and 'label'
         * @param object
         */
        function createControl(control) {
            var name = control.name,
                value = typeof control.value !== 'undefined' ? control.value : null,
                input = null,
                build = {};

            switch(control.type) {
                // 'select', 'radio', 'checkbox' requires Array 'list'.
                // Items can be '<value/label>', [<value>, <label>], or {value: <value>, 'label':<label>}
                case 'select':
                case 'checkbox':
                case 'radio':
                    // Common items
                    if (control.type === 'select') {
                        build.container = jQuery('<select class="custom-select" />')
                            .attr('id', name)
                            .attr('name', name);
                        build.element = jQuery('<option />');
                        build.label = null;
                        build.set = 'selected';
                    }
                    else {
                        build.container = jQuery('<div class="custom-control"/>')
                            .addClass('custom-' + control.type);
                        build.element = jQuery('<input />')
                            .attr('type', control.type)
                            .attr('name', name + (control.type === 'checkbox' ? '[]' : ''))
                            .addClass('custom-control-input');
                        build.label = jQuery('<label class="custom-control-label" />')
                        build.set = 'checked';
                    }
                    build.value = value;
                    // Build list and append to container
                    control.list.map(
                        function(item, index, _list) {
                            var a,
                                lbl = item,
                                val = item,
                                label = null,
                                element = build.element.clone(),
                                set = (typeof item.selected !== "undefined" && item.selected)
                                    || (typeof item.checked !== "undefined" && item.checked);

                            if ((a = Array.isArray(item)) || typeof item === 'object') {
                                val = a ? item[0] : item.value;
                                lbl = a ? item[1] : item.label;
                            }
                            element.attr('value', val);
                            set |= (build.value !== null && val == build.value);
                            if (set) {
                                element.attr(build.set, build.set);
                            }
                            if (build.label) {
                                label = build.label.clone();
                                element.attr('id', name + index.toString());
                                label.attr('for', name + index.toString());
                                label.text(lbl);
                            }
                            else {
                                element.text(lbl);
                            }
                            // Done, add to container
                            build.container
                                .append(element)
                                .append(label);
                        }
                    );
                    if (typeof control.label === "string" && control.label.length) {
                        input = jQuery('<label class="form-control-label" />')
                            .attr('for', name)
                            .text(control.label)
                            .add(build.container);
                    }
                    else {
                        input = build.container;
                    }
                    break;

                // Special control
                case 'switch':
                    input = jQuery('<div class="custom-control custom-switch" />')
                        .append(
                            jQuery('<input class="custom-control-input />')
                                .attr('type', 'checkbox')
                                .attr('id', name)
                                .val(value)
                        )
                        .append(
                            jQuery('<label class="custom-control-label" />')
                                .attr('for', name)
                                .text(control.label)
                        );
                break;

                // This will include everything else, the control.type will be the input.type.
                default:
                    input = jQuery('<div class="form-group" />');
                    build.element = jQuery('<input class="form-control" />')
                        .attr('type', control.type)
                        .attr('id', name)
                        .attr('name', name)
                        .val(value);
                    build.label = jQuery('<label>')
                        .attr('for', name)
                        .text(control.label);

                    input.append(build.label).append(build.element);
                    break;
            } // End switch

            // Wrap and return it
            return jQuery('<div class="chart-cntl col" />')
                .addClass('cntl_' + name)
                .append(input);
        }


        /**
         * Handler for changes to chart controls (except map)
         * @param object event
         * @return void
         */
        function chartControlChange(e) {
            if (refresh.timeout) {
                clearTimeout(refresh.timeout);
            }
            refresh.timeout = setTimeout(doRefreshChart, refresh.delay);
        }


        /**
         * Change the chart that is showing.
         * This is the change handler for the chart type selector
         * @param void
         */
        function doChangeChart() {
            // Get current control values
            var controlData = chartControls
                .find('input, select')
                .serialize();
            // Send and refresh the chart
            loadChartData(controlData, updateChart);
        }


        /**
         * Controls have changed, get values and rebuild form.
         * This should be called from chartControlChange()
         * @return void
         */
        function doRefreshChart() {
            // Get current control values
            var controlData = chartControls
                .find('.chart_cntl_active input, .chart_cntl_active select')
                .serialize();
            // Send and refresh the chart
            loadChartData(controlData, updateChart);
        }


        /**
         * Handler for resetting current chart
         * @return void
         */
        function doResetChart() {
            var controlData = {
                    reset: true,
                    activityStats: chartControls.find('#activityStats').val()
                };
            // Ask for the data
            loadChartData(controlData, updateChart);
        }


        /**
         * Initialize. Create control to select type of chart to display, create chart.
         * @param object Chart data, will include 'charts': array of objects with 'name' and 'label' properties
         * @return void
         */
        function init(chartData) {
            var chartSelector = createControl({
                        label: 'Chart Type',
                        name: 'activityStats',
                        type: 'select',
                        list: chartData.charts
                    })
                    .removeClass('chart-cntl')
                    .addClass('chart-selector')
                    .addClass('chart_cntl_active')
                    .on('change', doChangeChart),
                // Target 'select' within chartSelector
                chartSelectorSelect = chartSelector.find('select'),
                // Create a reset button
                chartResetBtn = jQuery('<button class="btn btn-outline-secondary" />')
                    .attr('type', 'button')
                    .text('Reset')
                    .on('click', doResetChart);

            // Wrap 'select' of selector with input-group
            chartSelectorSelect
                .wrap('<div class="input-group" />');
            // Insert reset button after the select element
            jQuery('<div class="input-group-append" />')
                .append(chartResetBtn)
                .insertAfter(chartSelectorSelect);
            // Add selector to controls
            chartSelector
                .appendTo(chartControls);

            // Create new chart with data
            newChart(chartData);

            // Listen for any other changes to controls
            chartControls.on(
                'change',
                '.chart-cntl.chart_cntl_active input, .chart-cntl.chart_cntl_active select',
                chartControlChange
            );
        }

    }
);