/**
 * App Primary JS
 *
 * Common functions and properties
 */


// strict mode
"use strict";


jQuery(document).ready(

    function() {
        jQuery('[data-toggle="tooltip"]').tooltip();
    }

);


/**
 * Format number with thousands
 * @param integer Integer number
 * @return string Formatted pretty
 */
function number_format(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
