/**
 * User Administration
 */


// strict mode
"use strict";

/* global */


jQuery(document).ready(

    function () {

        var new_user_login_modal = jQuery('#new-user-login'),
            userFld, pswdFld, addBtn;

        // If there is no new user modal we can bail
        if (new_user_login_modal.length) {

            // Listen for deletion events.
            jQuery('.user-login-table')
                .on(
                    'click',
                    '[name=delUserLogin]',
                    deleteUserAction
                );

            // Setup add form
            userFld = new_user_login_modal.find('[name=new_user_login]');
            pswdFld = new_user_login_modal.find('[name=new_user_pswd]');
            addBtn = new_user_login_modal.find('button.add-user-login');

            // Focus user field on modal open
            new_user_login_modal
                .on(
                    'shown.bs.modal',
                    function(e) {
                        // If there is an alert, empty and hide it
                        new_user_login_modal.find('.alert').empty().addClass('d-none');
                        // Focus on new field
                        userFld.get(0).focus();
                    }
                );

            // Disable Add Button until valid looking email address added
            userFld
                .on(
                    'keyup',
                    function(e) {
                        var user = userFld.val(),
                            good = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user)

                        if (good) {
                            enableBtnPrimary(addBtn);
                        }
                        else {
                            disableBtnPrimary(addBtn);
                        }
                    }
                )
                .trigger('keyup');

            // On form submit (addBtn click), ajax new data.
            addBtn
                .on(
                    'click',
                    addUserAction
                )
        }


        /**
         * Add new user - to be called from click of add button
         */
        function addUserAction(_e) {
            var loading = jQuery('<div class="text-center"><div class="spinner-border" role="status"><span class="sr-only">Adding...</span></div></div>');

            disableBtnPrimary(addBtn);
            new_user_login_modal.find('.alert').empty().addClass('d-none');
            new_user_login_modal.find('.modal-body').append(loading);
            jQuery.post(
                window.location.pathname,
                {
                    add: 'userLogin',
                    new_user: userFld.val(),
                    new_pswd: pswdFld.val()
                },
                function(response) {
                    var result;
                    try {
                        result = JSON.parse(response);
                    }
                    catch (_err) {
                        result = {error: response};
                    }
                    loading.remove();
                    enableBtnPrimary(addBtn);
                    if ('error' in result && result.error.length) {
                        new_user_login_modal.find('.alert')
                            .html(result.error)
                            .removeClass('d-none');
                    }
                    else {
                        new_user_login_modal.modal('hide');
                        jQuery('#user-list-rows').html(result.userListRefresh);
                    }
                }
            );
        }


        /**
         * Delete a user. This will be delete button
         */
        function deleteUserAction(_e) {
            var btn = jQuery(this),
                user = btn.closest('tr').attr('data-user');

            if (!confirm('Really delete ' + user + '?')) {
                return;
            }

            btn.attr('disabled', true);
            jQuery.post(
                window.location.pathname,
                {
                    del: 'userLogin',
                    del_user: user
                },
                function(response) {
                    var result;
                    try {
                        result = JSON.parse(response);
                    }
                    catch (_err) {
                        result = {error: response};
                    }
                    btn.attr('disabled', false);
                    if ('error' in result && result.error.length) {
                        btn.closest('tr').find('td').first().append(
                            '<div class="alert alert-danger">' + result.error + '</div>'
                        );
                    }
                    if ('userListRefresh' in result) {
                        jQuery('#user-list-rows')
                            .html(result.userListRefresh.length
                                ? result.userListRefresh
                                : '<tr colspan="2"><em>No Users to Display</td>'
                            );
                    }
                }
            );
        }


        function disableBtnPrimary(btn) {
            btn
                .removeClass('btn-primary')
                .addClass('btn-outline-primary')
                .attr('disabled', true);
        }

        function enableBtnPrimary(btn) {
            btn
                .removeClass('btn-outline-primary')
                .addClass('btn-primary')
                .attr('disabled', false);
        }
    }
);
