/**
 * Pagination controls
 */


// strict mode
"use strict";

/* global number_format */


/**
 * Create pagination controls.
 * Will install in passed class container(s).
 *
 * @param object 'page', 'perPage', and 'found'
 * @return object jQuery status and controls
 */
function paginationControls(pagination) {
    var controls = jQuery('<ul class="pagination justify-content-center"></ul>'),
        control, status, p,
        pages = Math.ceil(pagination.found / pagination.perPage),
        pageMax = 10,
        pageBeg = Math.max(0, Math.floor(pagination.page - (pageMax / 2))),
        pageEnd = Math.min(pageBeg + pageMax, pages);

    // Build controls
    control = jQuery('<li class="page-item" />')
        .append(
            jQuery('<a class="page-link" href="#" />').html('&laquo;').attr('data-pg', 0)
        );
    controls.append(
        pagination.page <= 0 ?
            control.addClass('disabled').children().prop('tab-index', '-1').prop('aria-disabled', 'true') :
            control
    );

    control = jQuery('<li class="page-item" />')
        .append(
            jQuery('<a class="page-link" href="#" />').text('<').attr('data-pg', pagination.page - 1)
        );
    controls.append(
        pagination.page <= 0 ?
            control.addClass('disabled').children().prop('tab-index', '-1').prop('aria-disabled', 'true') :
            control
    );

    for (p = pageBeg; p < pageEnd; p++) {
        control = jQuery('<li class="page-item" />')
            .append(
                jQuery('<a class="page-link" href="#" />').text(p + 1).attr('data-pg', p)
            );
        controls.append(
            p === pagination.page ? control.addClass('active') : control
        );
    }

    control = jQuery('<li class="page-item" />')
        .append(
            jQuery('<a class="page-link" href="#" />').text('>').attr('data-pg', pagination.page + 1)
        );
    controls.append(
        pagination.page > pages ?
            control.addClass('disabled').children().prop('tab-index', '-1').prop('aria-disabled', 'true') :
            control
    );

    control = jQuery('<li class="page-item" />')
        .append(
            jQuery('<a class="page-link" href="#" />').html('&raquo;').attr('data-pg', pages - 1)
        );
    controls.append(
        pagination.page >= pages ?
            control.addClass('disabled').children().prop('tab-index', '-1').prop('aria-disabled', 'true') :
            control
    );

    // Display controls
    if (pagination.found) {
        status = 'Showing <strong>' +
            number_format(pagination.page * pagination.perPage + 1) + '</strong> to ' +
            '<strong>' + number_format(Math.min(pagination.found, (pagination.page + 1) * pagination.perPage)) + '</strong> of ' +
            '<strong>' + number_format(pagination.found) + '</strong>';
    } else {
        status = '<strong><em>No records found</em></strong>';
    }
    return jQuery('<div class="text-center small">' + status + '</div>').add(controls);
}