/**
 * Table search
 */

// strict mode
"use strict";


jQuery(document).ready(

    function () {

        var searchTableForm = jQuery('form[name="searchTable"]'),
            searchContent = jQuery('.search-content'),
            searchRows = searchContent.closest('tr'),
            func, abc;

        if (searchTableForm.length && searchRows.length) {

            // Make jQuery's 'contains' case insensitive
            func = jQuery.expr.createPseudo(
                function (arg) {
                    return function (elem) {
                        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
                    };
                }
            );
            // depends on version
            jQuery.expr[":"].contains = func;
            jQuery.expr[":"].Contains = func;

            searchTableForm
                // Prevent it from submitting
                .on(
                    'submit',
                    function (e) {
                        e.preventDefault();
                        return false;
                    }
                )

                // Show form
                .removeClass('d-none');

            // Listen for typing and show/hide TR cells that match
            searchTableForm.find('input[type="text"]')
                .on(
                    'keyup',
                    function (e) {
                        var srch = jQuery(this).val(),
                            show;

                        if (srch.length === 0) {
                            searchRows.slideDown('fast');
                            searchRows.removeClass('srch').closest('table').slideDown('fast');
                        }
                        else {
                            show = searchContent.filter(':contains("' + srch.replace(/"/g, '//"') + '")').closest('tr');
                            show.addClass('srch').slideDown('fast');
                            searchRows.not(show).removeClass('srch').slideUp('fast');
                            // Hide/Show tables accordingly
                            show = show.closest('table');
                            show.slideDown('fast');
                            searchRows.closest('table').not(show).slideUp('fast');
                        }
                    }
                )
        }

    }
);