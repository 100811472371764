/**
 * Activity Log JS code
 */

// strict mode
"use strict";

/* global paginationControls */



jQuery(document).ready(

    function () {

        var activityLog = jQuery('#activityLog'),
            activityForm = activityLog.children('form'),
            activityLoading = activityLog.children('.loading'),
            activityResults = activityLog.children('.results'),
            activityPaging = activityLog.children('.result-paging'),
            pagination = {},
            page = 0,
            pageRefresh = 0,
            types = null,
            tbls = null,
            refreshTime = 10000,
            refreshTO = null;

        if (activityLog.length) {
            activityLog.children().toggleClass('d-none');
            activityPaging
                .on('click', 'a', clickPagintation);
            // Reload data after any filter changed
            activityForm
                .on('change', updateResults)
                .trigger('change');
            // Expand Detail on click
            activityResults
                .on(
                    'click touchEnd',
                    'td.cdetail',
                    function (e) {
                        var td = jQuery(this),
                            text = td.children('div'),
                            code = td.children('code');
                        // If no code or span, create them
                        if (text.length === 0) {
                            td.wrapInner('<div></div>');
                            text = td.children('div');
                        }
                        if (code.length === 0) {
                            code = jQuery('<code style="white-space:pre;"></code>')
                                .slideUp(0)
                                .html(JSON.stringify(JSON.parse(text.text()), null, 2))
                                .appendTo(td);
                        }
                        // Show or hide detail
                        if (td.hasClass('expand')) {
                            code.slideUp('fast');
                            text.slideDown('fast');
                            // Only restart auto update if not other items expanded
                            if (activityResults.find('.expand').length === 0) {
                                autoUpdateBeg();
                            }
                        } else {
                            autoUpdateEnd();
                            text.slideUp('fast');
                            code.slideDown('fast');
                        }
                        td.toggleClass('expand');
                    }
                );
        }

        function autoUpdateBeg() {
            if (refreshTO) clearTimeout(refreshTO);
            refreshTO = setTimeout(refreshResults, refreshTime);
        }

        function autoUpdateEnd() {
            if (refreshTO) clearTimeout(refreshTO);
            refreshTO = null;
        }


        function updateResults() {
            var data = {};

            autoUpdateEnd();

            // Initial load request lookup data
            if (types === null) data.init = 1;

            activityForm.find('input, select')
                .each(
                    function (index) {
                        var ele = jQuery(this);
                        data[ele.attr('id')] = ele.val();
                    }
                );
            data.logPage = page;
            pageRefresh = page;
            page = 0;

            // Show the statis loader
            activityLoading.slideDown(300);

            jQuery.getJSON(
                '?ajr=log',
                data,
                function (response) {
                    pagination = response.pagination;
                    if (response.tbls) tbls = response.tbls;
                    if (response.types) types = response.types;
                    updateDisplay(response.items);
                    activityLoading.slideUp(150);
                    autoUpdateBeg();
                }
            );
        }


        /**
         * Called to simply refresh results (pagination stays the same)
         */
        function refreshResults() {
            page = pageRefresh;
            updateResults();
        }


        /**
         * For list, update display
         * @param array activity log items
         * @return void
         */
        function updateDisplay(list) {
            var html = jQuery(
                '<table class="table">' +
                    '<thead>' +
                        '<tr>' +
                            '<th class="log-ts">Timestamp</th>' +
                            '<th class="log-type">Type</th>' +
                            '<th class="log-desc">Description</th>' +
                            '<th class="log-table">Table</th>' +
                            '<th class="log-id">ID</th>' +
                            '<th class="log-detail">Detail</th>' +
                        '</tr>' +
                    '</thead>' +
                '</table>'
            ),
                content = jQuery('<tbody id="logResultsContent"></tbody>');
            // Set the header
            activityResults.empty().html(html.append(content));

            // Append pagination status and controls
            activityPaging
                .empty()
                .append(paginationControls(pagination));
            // Keep what page we're on
            activityPaging.data('page', pagination.page);

            // Fill in all the table rows
            list.forEach(
                function (item) {
                    content.append(itemDisplay(item));
                }
            );
        }


        /**
         * Build HTML for single log item row
         * @param object Log Item
         * @return object jQuery row
         */
        function itemDisplay(item) {
            var row = jQuery('<tr></tr>').attr('id', item.log_id);
            row.append('<td class="log-ts">' + item.log_date2 + '</td>');
            row.append('<td class="log-type">' + getType(item.log_type) + '</td>');
            row.append('<td class="log-desc">' + item.log_desc + '</td>');
            row.append('<td class="log-table">' + getTbl(item.log_tbl) + '</td>');
            row.append('<td class="log-id">' + (item.log_id == "0" ? "" : item.log_id) + '</td>');
            row.append('<td class="log-detail cdetail"><div>' + item.log_detail + '</div></td>');
            return row;
        }


        /**
         * For passed type, return text label
         * @param integer Type
         * @param string Name
         */
        function getType(type) {
            if (type == "0") return "";
            var result = types.filter(
                function (cur) {
                    return cur.id == parseInt(type);
                }
            );
            return result.length ? result[0].label : type.toString();
        }


        /**
         * For passed table, return text label
         * @param integer Type
         * @param string Name
         */
        function getTbl(tbl) {
            if (tbl == "0") return "";
            var result = tbls.filter(
                function (cur) {
                    return cur.tbl == parseInt(tbl);
                }
            );
            return result.length ? result[0].label : tbl.toString();
        }


        /**
         * Click handler for pagination links
         * @param object event
         */
        function clickPagintation(e) {
            page = Math.max(0, parseInt(jQuery(this).attr('data-pg')));
            updateResults();
        }
    }
);