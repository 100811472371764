/**
 * DataViewer JS
 */

// strict mode
"use strict";

/* global paginationControls */



jQuery(document).ready(

    function () {

        var dataViewer = jQuery('#dataViewer'),
            dataModelForm = dataViewer.children('form'),
            dataLoading = dataViewer.children('.loading'),
            dataResults = dataViewer.children('.results'),
            currentDataModel = null,
            currentDataModelResults = null;

        if (dataViewer.length) {
            dataViewer.children().toggleClass('d-none');
            // Load data after any filter changed
            dataModelForm
                .on('change', selectDataModel)
                .trigger('change');

            dataViewer
                .on('click', 'a.page-link', clickPagintation);

            // Listen for data model filter changes
            dataResults
                .on('change', filterChanged);
            // Listen for detail view clicks
            dataResults
                // Allow links in content to work, but not trigger detail
                .on(
                    'click',
                    'a:not(.page-link)',
                    function (e) {
                        e.stopPropagation();
                    }
                )
                .on(
                    'click',
                    '.data-view-detail > td:not(.std)',
                    function (e) {
                        if (checkToggleJSON(this)) {
                            e.stopPropagation();
                        } else {
                            jQuery(this).addClass('std');
                        }
                    }
                )
                // Click on row displays detail view
                .on('click', '.data-view-detail', viewDetail);
        }


        /**
         * Passed should be container with content that may be JSON. Check if contents are json and toggle if so
         * @param object Container (td|div)
         * @return bool Is JSON data
         */
        function checkToggleJSON(elem) {
            var cntr = jQuery(elem),
                text = cntr.children('div'),
                code = cntr.children('code'),
                data = null,
                result = true;

            try {
                // If no text div, check if content is even json, if not bail
                if (text.length === 0) {
                    data = cntr.text();
                    if (data.length)
                        data = JSON.parse(data);
                    console.log(data);
                    if (typeof data !== 'object')
                        throw 'Not JSON';

                    // Create a test container
                    cntr.wrapInner('<div></div>');
                    text = cntr.children('div');
                    // Create a code container
                    code = jQuery('<code style="white-space:break-spaces;"></code>')
                        .slideUp(0)
                        .html(JSON.stringify(data, null, 2))
                        .appendTo(cntr);
                }
                // Show or hide detail
                if (cntr.hasClass('expand')) {
                    code.slideUp('fast');
                    text.slideDown('fast');
                } else {
                    text.slideUp('fast');
                    code.slideDown('fast');
                }
                cntr.toggleClass('expand');
            } catch (err) {
                result = false;
            }
            return result;
        }


        /**
         * Data Model selector changed. Hide current showing results,
         * and show results for selected set.
         */
        function selectDataModel() {
            currentDataModel = dataModelForm.find('[id=dataMdl]').val();
            currentDataModelResults = dataResults.children('.results-' + currentDataModel);

            // Hide other results
            dataResults.children().not(currentDataModelResults)
                .removeClass('selected')
                .addClass('d-none');

            // Show current data model results
            currentDataModelResults
                .addClass('selected')
                .removeClass('d-none');

            // If nothing is there, first time, trigger an update.
            if (currentDataModelResults.children().length === 0) {
                updateResults();
            }
        }


        /**
         * Filter changed. Reset page and update results
         */
        function filterChanged() {
            // Null the current page
            currentDataModelResults.data('page', null);
            // Submit and update results
            updateResults();
        }


        /**
         * For the currently selected results, submit values on it's filter form
         * and display results.
         */
        function updateResults() {
            var parts,
                page,
                data = {
                    dataMdl: currentDataModel
                },
                // Response will either be just results unless no filter elements where found
                resultsContainer = currentDataModelResults.children('.dataMdl-results-container'),
                updateView = resultsContainer.length > 0,
                paging = null;

            // Get data from form
            currentDataModelResults.find('input, select')
                .each(
                    function (index) {
                        var ele = jQuery(this);
                        data[ele.attr('id')] = ele.val().replace(/(^\s+|\s+$)/gm, '');
                    }
                );

            page = currentDataModelResults.data('page');
            if (page) data.page = page;

            // Special handling of dataTbl. Field may be <dataModel>-<table>, if so split it.
            if (data.dataMdl.indexOf('-') >= 0) {
                parts = data.dataMdl.split('-');
                data.dataMdl = parts[0];
                data.dataTable = parts[1];
            }


            // If initial view, insert paging containers
            if (!updateView) {
               // Add paging - will have to move this when results received
                paging = jQuery('<div class="result-paging" />')
                    .append('<div class="loader-wide">'
                        + '<div class="bar bar1"></div>'
                        + '<div class="bar bar2"></div>'
                        + '<div class="bar bar3"></div>'
                        + '<div class="bar bar4"></div>'
                        + '<div class="bar bar5"></div>'
                        + '<div class="bar bar6"></div>'
                        + '<div class="bar bar7"></div>'
                        + '<div class="bar bar8"></div>'
                        + '</div>');
                currentDataModelResults.append(paging);
            }

            // Show the status loader
            dataLoading.slideDown(300);
            currentDataModelResults.addClass('loading');
            jQuery.getJSON(
                '?ajr=' + data.dataMdl,
                data,
                function (response) {
                    // If filter included prepend it to results
                    if ('filter' in response) {
                        currentDataModelResults.prepend(response.filter);
                    }
                    // If name included prepend it to results
                    if ('name' in response) {
                        currentDataModelResults.prepend(response.name);
                    }
                    // If no results container, create one and append copy of paging after
                    if (resultsContainer.length === 0) {
                        resultsContainer = jQuery('<div class="dataMdl-results-container" />');
                        currentDataModelResults.append(resultsContainer);
                        currentDataModelResults.append(paging.clone());
                    }
                    // Dpdate results content
                    resultsContainer.empty().html(response.html);
                    // Update paging and controls
                    currentDataModelResults.find('.result-paging > *').not('.loader-wide').remove();
                    currentDataModelResults.find('.result-paging')
                        .append(paginationControls(response.pagination));
                    // Keep what page we're on
                    currentDataModelResults.data('page', response.pagination.page);

                    currentDataModelResults.removeClass('loading');
                    dataLoading.slideUp(150);
                }
            );
        }


        /**
         * View detail of a result item.
         * This will be the click handler for any .data-view-detail element.
         * The click object should have a data-key json string which is the key for that item.
         * That key is passed as a request and html returned and placed in a modal.
         * displayed in modal
         * @param object event
         */
        function viewDetail(e) {
            var targetRow = jQuery(e.target).closest('tr'),
                data = {
                    detail: targetRow.attr('data-key'),
                    dataMdl: dataModelForm.find('[id=dataMdl]').val(),
                    dataTable: null
                },
                parts = null,
                label,
                modal;

            // Special handling of dataTbl. Field may be <dataModel>-<table>, if so split it.
            if (data.dataMdl.indexOf('-') >= 0) {
                parts = data.dataMdl.split('-');
                data.dataMdl = parts[0];
                data.dataTable = parts[1];
            }

            // Prevent link default behavior
            e.preventDefault();
            // Create or get modal structure
            modal = getModal();
            // Set content to loader
            modal.find('.modal-body').empty().html('<div class="spinner-border" style="height:50vh;" role="status" />');
            // Try to find something to display
            label = targetRow.children('[class$="name"]');
            modal.find('.modal-title').empty().html('Show Detail' + (label.length ? ': ' + label.first().text() : ''));
            // Display
            modal.modal('show');
            // Perform Request
            jQuery.getJSON(
                '?ajr=' + data.dataMdl,
                data,
                function (response) {
                    modal.find('.modal-body').empty().html(response.html);
                }
            )
        }


        /**
         * Create or get modal stucture
         * @return object Model container
         */
        function getModal() {
            var modal = jQuery('#detailModal');

            if (modal.length === 0) {
                modal = jQuery(
                    '<div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModalLabel" aria-hidden="true"> \
                        <div class="modal-dialog modal-xl" role="document"> \
                            <div class="modal-content"> \
                                <div class="modal-header"> \
                                <h5 class="modal-title" id="detailModalLabel">Modal title</h5> \
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
                                    <span aria-hidden="true">&times;</span> \
                                </button> \
                                </div> \
                                <div class="modal-body"></div> \
                            </div> \
                        </div> \
                    </div>');
                modal.appendTo(document.body);
                modal.on(
                    'click',
                    '.data-view-value:not(.std)',
                    function (e) {
                        if (checkToggleJSON(this)) {
                            e.stopPropagation();
                        } else {
                            jQuery(this).addClass('std');
                        }
                    }
                );

            }
            return modal;
        }


        /**
         * Click handler for pagination links
         * @param object event
         */
        function clickPagintation(e) {
            e.preventDefault();
            currentDataModelResults.data('page', Math.max(0, parseInt(jQuery(this).attr('data-pg'))));
            updateResults();
        }

    }
);