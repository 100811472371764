/**
 * Forms
 */


// strict mode
"use strict";



jQuery(document).ready(

    function () {

        // Forms layout option to set create form option
        var form_create_containers = jQuery('.form-create-hs-contact-selection'),
            form_create_marketable,
            create_summary;

        // Are we on that form?
        if (form_create_containers.length) {
            // Get destination form radios
            form_create_marketable = {
                Y : form_create_containers.find('[name$=Y]:checked').eq(0),
                N : form_create_containers.find('[name$=N]:checked').eq(0)
            };
            // Summary is area at top of form list, also where 'no' form can be set.
            create_summary = jQuery('#mdd-create-forms');
            // Update the summary
            ['Y', 'N'].forEach(updateSummary);
            // Summary hidden initially
            create_summary.removeClass('d-none')

            // Containers are likely hidden, show em.
            form_create_containers.removeClass('d-none');
            // Listen for changes
            form_create_containers.on(
                'change',
                'input[type=radio]',
                confirmSelection
            )
        }

        /**
         * User clicked on a form radio.
         * - Confirm not already checked.
         * - Confirm with user they want to make the change.
         * - Perform AJAX operation to save selection.
         */
        function confirmSelection(e) {
            var radio = jQuery(e.target),
                isMarketable = radio.prop('name').match(/Y$/) ? 'Y' : 'N';

            // Already checked or if user doesn't commit - bail
            if (radio == form_create_marketable[isMarketable]) {
                e.preventDefault();
                return false;
            }
            // If user doesn't commit - bail
            if (!confirm('This will set the form used for creating ' + (isMarketable ? 'Marketable' : 'Non-Marketable') + 'Contacts in HubSpot. Continue?')) {
                if (form_create_marketable[isMarketable].length) {
                    form_create_marketable[isMarketable].prop('checked', true);
                }
                radio.prop('checked', false);
                e.preventDefault();
                return false;
            }

            // Track last selected form
            form_create_marketable[isMarketable] = radio;

            // Push to app to save selection
            saveSelection(radio.val(), isMarketable);
        }

        function saveSelection(formid_key, isMarketable) {
            var post_data = {};

            post_data[ isMarketable == 'Y' ? 'marketable_contact_form' : 'non_marketable_contact_form' ] = formid_key;
            jQuery.post(
                '?ajr=contactCreate',
                post_data,
                function(data) {
                    if (data !== 'OK') {
                        alert(data);
                    }
                    else {
                        updateSummary(isMarketable);
                    }
                }
            )
        }

        function updateSummary(yn) {
            var block = jQuery('.summary-marketable-contact-form-' + yn),
                radio = block.find('.no-selection input'),
                other = block.find('.selection'),
                choice = form_create_marketable[yn],
                tr;

            // Should no radio for the group be selected, select the summary radio
            if (choice.length === 0) {
                radio.prop('checked', true);
                form_create_marketable[yn] = choice = radio;
            }

            // If choice is 0, hide other
            if (choice.val() == '0') {
                other.addClass('d-none');
            }
            else {
                tr = choice.closest('tr');
                // Setup link to selected form and content to name of selected form
                other.find('a').attr('href', '#' + tr.attr('id')).html(tr.find('.form-name').html());
                // Show other
                other.removeClass('d-none');
            }
        }
    }
);
