/**
 * Error log
 */

// strict mode
"use strict";

/* global */



jQuery(document).ready(

    function () {

        var errorLog = jQuery('.error-log'),
            items = errorLog.children('.error-log-items'),
            filter = errorLog.children('.error-log-filter'),
            filter_date = filter.find('[name=filter_date]'),
            filter_text = filter.find('[name=filter_text]'),
            filter_e401 = filter.find('[name=filter_e401]');

        if (errorLog.length && filter.length) {
            // Listen for changes to the filter
            filter_date.on('change', filterLoadDate)
            filter_e401.on('click', filterItems);
            filter_text.on('keyup', filterItems);

            // Fore update
            filterSetup();

            // Show the filter
            filter.removeClass('d-none');
        }


        // function buildFilterDates() {
        //     var dates = [];

        //     if (filter_date.length === 0) {
        //         return;
        //     }
        //     // For each item with a data-date
        //     items.children('[data-date]').each(
        //         function(index, ele) {
        //             var date = jQuery(ele).attr('data-date');
        //             // Add only those dates not already in list
        //             if (dates.indexOf(date) < 0) {
        //                 dates.push(date);
        //                 // Append an option after the first 'All Dates' item.
        //                 // This should place the newest dates near top.
        //                 jQuery('<option />').attr('value', date).text(date).insertAfter(
        //                     filter_date.children().first()
        //                 );
        //             }
        //         }
        //     );
        //     // Assuming we found dates, set the 2nd (after 'All') option current
        //     if (dates.length) {
        //         filter_date[0].selectedIndex = 1;
        //     }
        // }


        function filterLoadDate(_e) {
            var date = filter_date.val();

            items.html('<div class="text-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>')
            jQuery.post(
                window.location.pathname,
                {get: 'error-log-items', date: date},
                function(response) {
                    // Response will be HTML
                    items.html(response);
                    filterSetup();
                }
            )
        }


        // Call after initial load and when new items are loaded
        function filterSetup() {
            if (items.children('p').length > 1000) {
                // Doing this way as item also has classes setting the display property with more specitivity
                filter.find('.filter-control-e401').attr('style', 'display:none !important');
                filter.find('.filter-control-text').attr('style', 'display:none !important');
            }
            else {
                filter.find('.filter-control-e401').removeAttr('style');
                filter.find('.filter-control-text').removeAttr('style');
                filterItems(null);
            }
        }


        function filterItems(_e) {
            var // date = filter_date.val(),
                text = filter_text.val().replace(/(^\s+|\s+$)/g, ''),
                e401 = filter_e401.prop('checked'),
                show = items.children('p'),
                none = items.siblings('.alert');

            // if (date && date.length) {
            //     show = show.filter('[data-date="' + date + '"]');
            // }
            if (text.length) {
                show = show.filter(':contains(\'' + text.replace(/'/, '\\\'') + '\')')
            }
            if (e401) {
                show = show.not('[data-401]');
            }
            // Show those to show and hide those not
            items.children().not(show.slideDown('fast')).slideUp('fast');

            // If nothing shows, show something, hide otherwise
            if (show.length) {
                none.slideUp('fast');
            }
            else {
                if (none.length === 0) {
                    none = jQuery('<div class="alert alert-info">No items matched the filter criteria</div>')
                        .insertAfter(items);
                }
                none.slideDown('fast');
            }
        }
    }
);
