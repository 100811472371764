/**
 * Queue
 */


// strict mode
"use strict";

/* global setInterval */


jQuery(document).ready(

    function () {

        var refreshDelay = 5000,
            systemStatus = jQuery('#systemStatus'),
            serverAutoRun = jQuery('#server-auto-run'),
            autoRunPrev = null,
            cacheBuster = 0;

        if (systemStatus.length) {
            refreshStatus();
            setInterval(refreshStatus, refreshDelay);

            jQuery(document)
                .on(
                    'click',
                    '.server-auto-run button',
                    function(e) {
                        var btn = jQuery(this),
                            enable = !btn.hasClass('is-running');

                        btn.prop('disabled', true);
                        jQuery.post(
                            window.location.pathname,
                            { set: 'autoRun', enable: enable ? 'Y' : 'N' },
                            function(response) {
                                btn.prop('disabled', false);
                            }
                        )
                        showAutoRunButtons(enable);
                    }
                );

            // Listen for the edit modal, disable the auto run when open
            jQuery(document)
                .on(
                    'show.bs.modal',
                    function(e) {
                        autoRunPrev = serverAutoRun.hasClass('is-running');
                        // console.log(['show.bs.modal', autoRunPrev]);
                        if (autoRunPrev) {
                            serverAutoRun.find('button.is-running').trigger('click');
                        }
                    }
                )
                .on(
                    'hide.bs.modal',
                    function(e) {
                        // console.log(['hide.bs.modal', autoRunPrev]);
                        if (autoRunPrev !== null && autoRunPrev) {
                            serverAutoRun.find('button.is-stopped').trigger('click');
                        }
                    }
                );

        }

        function refreshStatus() {
            jQuery.getJSON(
                '?get=status',
                { cbr: cacheBuster++ },
                function (response) {
                    // AutoRun status
                    showAutoRunButtons(response.autoRun);

                    // Update SyncQueues
                    response.syncGroups.forEach(
                        function(syncGroup) {
                            syncGroup.statuses.forEach(
                                function(status) {
                                    changeStatusIfNew(status, 'open', true);
                                    changeStatusIfNew(status, 'total', true);
                                    changeStatusIfNew(status, 'last', false);
                                }
                            )
                        }
                    );
                    setTimeout(
                        function() {
                            jQuery('.status-item.changed')
                                .removeClass('changed');
                        },
                        refreshDelay - 100
                    );
                }
            );
        }

        function showAutoRunButtons(autoRun) {
            if (autoRun) {
                serverAutoRun.removeClass('is-stopped').addClass('is-running');
            }
            else {
                serverAutoRun.removeClass('is-running').addClass('is-stopped');
            }
        }

        function changeStatusIfNew(status, prop, formatAsNumber) {
            var item = jQuery('#' + status.id + '-' + prop),
                nval = formatAsNumber ? numberWithCommas(status[prop]) : status[prop];
            if (item.length && item.text() != nval) {
                item.text(nval).addClass('changed');
            }
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
);
